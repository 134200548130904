

.footerComponent {
  height: 50vh;
  background-color: #ED3030;
  color: white;
  font-size: 2em;
}

.footerContainer {
 
  margin: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

 
}


.footerLogo {
  filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    max-width: 4em;

    margin-bottom: 4rem;
}


.footerMainLink {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footerSubLink {
  font-size: 1rem;

}
.footerSubLink a {
  text-decoration: none !important;
  color: white !important;

}

.footerItem {
  margin-bottom: 2rem;

}

@media screen and  (max-width: 800px) {
  .footerLogo {
      max-width: 3em;

  }
  .footerMainLink {
    font-size: 2rem;

  }
  
  .footerSubLink {
    font-size: 0.9rem;
  }
  
  .footerContainer {
    flex-direction: column;
    justify-content: space-between;
  }

  .footerComponent {
    height: 60vh;
   
  }

  
}