.dataInsightsComponent {
    height: 100%;
    padding-bottom: 25vh;
    width: 100vw;
}

.dataInsightsContainer {
    background-image: linear-gradient(-45deg, #4DB5D6 50%, #ED3030 100%);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
    width: 100vw;
}

.dataInsight {
    display: flex;
    position: relative;
    justify-content: space-around;
}

.col1 {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    animation: moveLeftRight 9s ease-in-out infinite;
}

.col2 {
    animation: moveLeftRight 8s ease-in-out infinite;
}

.col3 {

    animation: moveLeftRight 9s ease-in-out infinite;
}

.col3row1 {
    display: flex;
    flex-direction: row;
}

.col1, .col2, .col3 {
    position: relative;
}





.dataInsightsHeading {
    z-index: 10;
    font-size: 3em;
    color: white;
    font-weight: bolder;
    line-height: 1em;
    margin-top: 30px;
    margin-bottom: 30px;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    word-spacing: 100vw;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: grey;
    
}

.widget {
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
}

.showBorder {
    border: 2px solid #3bf50d;
}


.widgets {
    display: flex;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    75% {
        background-position: 50% 75%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes moveLeftRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-200px);
    }
}

@keyframes moveLeftRightAgro {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-550px);
    }
}

@media screen and (max-width: 800px) {
    .dataInsightsComponent {
        overflow: hidden;
    }

    .dataInsight img {
        transform: scale(0.9, 0.9);
    }

    .dataInsightsHeading {
        z-index: 99999;
        font-size: 2.5em;
    }


    .moveLeftRightLevel1 {
        animation: moveLeftRightAgro 9s ease-in-out infinite;

    }
    .moveLeftRightLevel2 {
        animation: moveLeftRightAgro 10s ease-in-out infinite;

    }
    .moveLeftRightLevel3 {
        animation: moveLeftRightAgro 9s ease-in-out infinite;

    }
}