
.benefitsContainer {
  padding-bottom: 10vh;
  background-color: #F3F2F2;

}

.benefitsHeading {
  font-size: 4em;
  line-height: 1;
  font-weight: bold;
  margin-top: 3vh;
}


.benefitsText {
  font-size: 1.7em;
  line-height: 1;
  color: #727070;
 
}

.benefitItemsSection {
  margin-top: 7vh;
}

.benefitsItem {
  display: flex;

}

.benefitItemIcon {
  color: #ED3030;
}

.benefitsItem img {
  max-width: 25px;
  max-height: 25px;
  margin: 1px;
  font-color: #ED3030;

}

.benefitsItemText h3 {
  color: #000000;
}
.benefitsItemText {
  color: #727070;
  margin: 4px;

}







.benefitsItem h3 {
 font-size: 1.3em;
 font-weight: bold;
 line-height: 1;
}

@media screen and  (max-width: 800px) {

  .benefitsHeading {
      font-size: 3em;
      font-weight: bold;
      line-height: 1;
      width: auto;
     
  }

  .benefitsText{
      text-align: justify;
      font-size: 1.2em;

  }
}