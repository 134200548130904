body {

    background-color: #FFFDF9;
}



.splashComponent {
    width: 100%;
    padding-bottom: 15vh;
    
}

.splashTopSection {
    padding-bottom: 8vh;
    padding-top: 15vh
}

.splashTextSection {
    /* border: 2px solid #3bf50d; */
    display: flex;
    flex-direction: column;
    gap: 1rem; 
}


.splashHeading, .splashHeadingMobile {
    color: #ED3030; 
    font-weight: bold;
    font-size: 2.8rem;
}

.splashHeadingMobile {
    display: none;
}

.splashText {
    font-size: 1.4em;
    line-height: 1.1;
    color: #727070;
    text-align: justify;
}
.splashButton {
    color: #4DB5D6;
}

.imgSplash {
    /* width: 800px; */
    /* border: 2px solid #3bf50d; */
    /* justify-content: flex-end; */
}

.imgSplash img {
    object-fit: contain;
    box-shadow: -2px 2px 169px 10px rgba(0, 0, 0, 0.25);
    color: #ED3030 ;
    max-width: 100%;
    max-height: 100%; 
    transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
}

.splashBottomSection {
  padding-bottom: 5vh;
}

.splashBottomItem {


}

.splashBottomItemHeading {
    font-weight: bold;
}

.splashBottomItemText {
    color: #727070;
}

.splashButton {
    background-color: #4DB5D6 !important;
    color: white !important;

  }




@media screen and  (max-width: 800px) {

    .splashHeading, .splashText, .splashButtonContainer {
        text-align: center;
    }

    .imgSplash img {
        transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -webkit-transform: scale(0.9, 0.9);
    }    

    .splashHeading {
        /* display: none; */
    }

    .splashText {
        font-size: 1.3em;
        text-align: justify;
        margin: 0px 30px;
    }

    .noPadding {
        padding: 0px !important;
    }

    .splashHeadingMobile {
        display: none;
        /* position: absolute;
        display: flex;
        justify-content: center;
        right: 20% !important;
        top: 30vh !important;
        z-index: 1; */
       
    }
    .splashTopSection {
        padding-bottom: 8vh;
        padding-top: 1vh
    }
 
  }

  @media screen and  (max-width: 1200px) { 

    .splashHeading {
        font-size: 2rem;
    }

  }


  * {
      /* outline: 2px solid red; */
  }