html {
  scroll-behavior: smooth;
}

.navbar {
  /* Add any specific styles if needed */
}

.nav {
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: all 0.5s ease-out;
  padding: 0;
}

.navScroll {
  margin-top: 0vh;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0px 0px 2px 2px;
  filter: drop-shadow(0px 1px 10px black) !important;
  transition: all 0.5s ease-out;
}

.navLogo {
  display: flex;
  justify-content: center;
  transition: all 0.7s ease-out;
}

.navLogo img {
  max-width: 6rem;
  max-height: 6rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: all 0.7s ease-out;
}

.navLogoImgScroll {
  margin-top: 0 !important;
  transition: all 0.7s ease-out;
  transform: scale(0.7, 0.7);
}

.nav ul {
  padding-top: 1.5vh;
  font-size: 2em;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 2.5em;
  padding-left: 0;
  transition: all 0.8s ease-out;
}

.navULScroll {
  padding-top: 0.5vh !important;
  margin: 0;
  gap: 2.4em;
  font-size: 1.8em !important;
  transition: all 0.8s ease-out;
}

.nav ul li {
  color: #ED3030;
  font-weight: light;
}

.nav ul li a {
  color: #ED3030;
  font-weight: light;
}

.loginButton {
  margin-top: 2vh !important;
  font-size: 2.3rem !important;
  color: #ED3030;
  max-height: 50px;
  transition: all 0.7s ease-out;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.30));
}

.loginButtonScroll {
  margin-top: 2vh !important;
  color: #ED3030;
  font-size: 2rem;
  transition: all 0.7s ease-out;
  /* transform: scale(0.9, 0.9); */
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.30));
}

.loginDropDown {
  background-color: #FFFDF9 !important;
  border: none !important;
}

.loginForm {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  margin: 0.5rem;
  width: 400px;
}

.loginForm .input {
  margin: 0.5rem;
}

.submitButton {
  margin-top: 1em !important;
  background-color: #4DB5D6 !important;
  color: white !important;
  width: 50%;
  margin-left: 25% !important;
}

.loginForm button {
  justify-content: center !important;
}

.bottomLinks {
  display: flex;
  justify-content: space-around;
  gap: 8em;
  font-size: 1rem;
  margin: 1em 0 0.5em 0;
}

.bottomLinks div {
  margin: 1rem;
}

.hamburgerButton Button {
  display: none !important;
  color: white;
  background-color: transparent !important;
  margin-top: 10px !important;
  font-size: 1rem !important;
}

.hamburgerButton i {
  color: #ED3030;
  font-size: 1.9em !important;
}

.showBorder {
  border: 2px solid red;
}

/* @media screen and (max-width: 960px) {
  .navLogo img {
    transition: all 0.7s ease-out;
    transform: scale(1, 1);
  }
} */

@media screen and (max-width: 768px) {
  .loginDropDown {
    width: 260px;
  }
  .loginForm{
    width: 260px;
  }
  .bottomLinks {
    display: flex;
    justify-content: space-around;
    gap: 2em;
    font-size: 1rem;
    margin: 0.5em 0 0.2em 0;
  }
  
  .bottomLinks div {
    margin: 0.5rem;
  }
  .nav ul {
    display: none; /* Hides the navigation links */
  }

  .navbar {
    margin-bottom: 10vh;
  }

  .navLogo {
    margin-top: 5px;
  }


  .navLogo img {
    width: 3.5rem;
    height: 3rem;
  }

  .navLogoImgScroll {
    margin-top: 0 !important;
    transition: all 0.7s ease-out;
    transform: scale(0.9, 0.9);
  }

  .loginButton {
    margin-top: 1vh !important;
    max-height: 110px;
    margin-top: 0;
    font-size: 2rem !important;
    /* font-size: 1.5rem; */
  }


  .loginButtonScroll {
    margin-top: 0.5vh !important;
    font-size: 1.8rem;
  }

  .loginButton img {
    display: inline; /* Show the user icon */
    
  }

  .navLogo img {
    /* transition: all 0.7s ease-out;
    transform: scale(0.6, 0.6); */
    
  }

  .dropdown i {
    display: inline !important; /* Ensure the user icon is visible */
    margin-left: -10px;
    /* font-size: 1.3em !important; */
  }
}
