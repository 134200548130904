.trasnmitContainer {


}

.transmitComponent {
    margin-bottom: 15vh;
}

.showborder {

    /* border: 2px solid #3bf50d; */

}

.transmitImg img{
    /* transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -webkit-transform: scale(0.3, 0.3); */
    height: 600px;
    width: 600px;

}

.transmitHeading {
    font-size: 5em;
    line-height: 1;
    font-weight: bold;

}

.transmitText {
    font-size: 2em;
    line-height: 1;
    color: #727070;
}



@media screen and  (max-width: 800px) {

    .transmitHeading {
        font-size: 3em;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
        
    }

    .transmitText{
        text-align: justify;
        font-size: 1.2em;
        margin-bottom: 3vh;

    }


    #iconsSvgWrap {
        position: relative;
       left: -50px !important;
       max-width: 120vw;
    }

    
  }