.searchComponent {
    height: 100%;
    padding-bottom: 20vh;
}

.searchGrid {
    gap: 1em;
}

.searchCardContainer {
    height: 28vh;
    width: 100% !important;
    overflow: hidden;

}

.searchCard {
    background-color: #F1F1F2;
    border-radius: 5px;
    margin: 1.4em;
    overflow: hidden;
    padding: 1em;
}


.flexColumn {
    display: flex;
    flex-direction: row !important;

}

.verticalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchCardHeading {
    font-size: 2.5em;
    font-weight: 900;
    color: #262626;
    line-height: 1;
    width: 100%;
    text-align: center;
}

.searchCardText {
    font-size: 1.2em;
    line-height: 1.5;
    color: #58595B;
    text-align: justify;
    width: 100%;
}

.fixWidth {
    max-width: 45%;
}

#ASC,
#report,
#savedSearch,
#tryingToMigrate {
    text-align: center;
}

.oneWordMe {
    word-spacing: 100vw;
    text-align: left !important;
}

.centerText {
    text-align: center;
}

.removeAll {
    padding: 0 !important;
    margin: 0 !important;
}

.setHeight {
    height: 100%;
    
}

.setWidth {
    width: 100% !important;
}

@media screen and (max-width: 800px) {
    .searchCardHeading {
        font-size: 1.5rem;
    }

    .searchCardText {
        font-size: 1rem;
    }

    .searchCard {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .searchGrid {
        gap: 0;
    }


.flexColumn {
    display: flex;
    flex-direction: column !important;

}

.oneWordMe {
    word-spacing: 0;
   
}


}

@media screen and (max-width: 1200px) {
    .searchCardHeading {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
    }

    .searchCardText {
        font-size: 1.5rem;
        margin-bottom: 3vh;
    }

    #ASC {
        line-height: 1;
    }


    #tryingToMigrate {
        line-height: 1;
        overflow: hidden;
    }
}
