.connectivityComponent {
    height: 100%;
    padding-bottom: 25vh;
}

.connectivityContainer {
    display: flex;
    justify-content: center;
}

.connectivityHeading {
    font-size: 4.5em;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  

    
}

.connectivitysText {
    font-size: 2em;
    line-height: 1;
    color: #727070;
    width: 90%;
 
}

.conntectorIconSection {
    display: flex;
    justify-content: center;
    gap: 2.5em;
}

.conntectorIconSection img {
  
    width: 100px;
    height: 100px;
    /* border: 2px solid #3bf50d;; */
    gap: 1em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.office365 {
    height: 90px !important;
}

.networkFolder {
    width: 95px !important;
}


.showBorder {
    border: none;
}






@media screen and  (max-width: 800px) {

    .connectivityHeading {
        font-size: 3em;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
        
    }

    .connectivitysText{
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 3vh;
    }

    .conntectorIconSection img {
  
        max-width: 60px;
        max-height: 60px;
       
    }

    .connectivityComponent {
        height: 100%;
        padding-bottom: 10vh;
    }
 
  }