
.pricingContainer {
  padding-bottom: 20vh;
 
}

.plan {
  display: flex;
  flex-direction: column;
  gap: 1.9em;
  margin: 10px;
  height: 62vh;
 

}
.pricingContainerGrid {
  gap: 2em;
}

/* .planCard {
  width: 30vw !important;
} */

.pricingHeading {
  font-size: 3em;
  line-height: 1;
  font-weight: bold;
  width: 100%;
  padding: 5px;
  text-align: center;
}


.price {

  color: black;
  font-size: 1.8em;
}
.pricingText {
  font-size: 1.5em;
  line-height: 1;
  color: #727070;
  height: 12vh;
 
}

.pricingSubHeading {
  font-weight: bolder;
  font-size: 1.4em;
  line-height: 1;
  color: #1d1d1d;
  margin-bottom: 1vh;;
  display: flex;
 
}

.pricingSubText {
  font-weight: normal;
  padding-left: 5px;

}

.pricingConnectorsTypeIcon {
  display: flex;
  justify-content: space-around !important;
  gap: 0.3rem;
  font-weight: normal;
  padding-left: 10px;

}


.plan1 { 
  border: 3px solid #C4C4C4;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.plan2 { 
  border: 3px solid #00AEEF;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.plan3 { 
  border: 3px solid #B94926;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}


.planButton {
  background-color: #4DB5D6 !important;
  color: white !important;
}