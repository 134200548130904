body {

    margin: 0;
    background-color: #FFFDF9;
}

code {

}

body {
    background-color: #FFFDF9;
    /* padding: 20px; */
}


.landingWrapper {
    display:flex;
    flex-direction: column;
}
html {
  scroll-behavior: smooth;
}

.navbar {
  /* Add any specific styles if needed */
}

.nav {
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: all 0.5s ease-out;
  padding: 0;
}

.navScroll {
  margin-top: 0vh;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0px 0px 2px 2px;
  filter: drop-shadow(0px 1px 10px black) !important;
  transition: all 0.5s ease-out;
}

.navLogo {
  display: flex;
  justify-content: center;
  transition: all 0.7s ease-out;
}

.navLogo img {
  max-width: 6rem;
  max-height: 6rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: all 0.7s ease-out;
}

.navLogoImgScroll {
  margin-top: 0 !important;
  transition: all 0.7s ease-out;
  transform: scale(0.7, 0.7);
}

.nav ul {
  padding-top: 1.5vh;
  font-size: 2em;
  list-style-type: none;
  display: flex;
  justify-content: center;
  grid-gap: 2.5em;
  gap: 2.5em;
  padding-left: 0;
  transition: all 0.8s ease-out;
}

.navULScroll {
  padding-top: 0.5vh !important;
  margin: 0;
  grid-gap: 2.4em;
  gap: 2.4em;
  font-size: 1.8em !important;
  transition: all 0.8s ease-out;
}

.nav ul li {
  color: #ED3030;
  font-weight: light;
}

.nav ul li a {
  color: #ED3030;
  font-weight: light;
}

.loginButton {
  margin-top: 2vh !important;
  font-size: 2.3rem !important;
  color: #ED3030;
  max-height: 50px;
  transition: all 0.7s ease-out;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.30));
}

.loginButtonScroll {
  margin-top: 2vh !important;
  color: #ED3030;
  font-size: 2rem;
  transition: all 0.7s ease-out;
  /* transform: scale(0.9, 0.9); */
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.30));
}

.loginDropDown {
  background-color: #FFFDF9 !important;
  border: none !important;
}

.loginForm {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  margin: 0.5rem;
  width: 400px;
}

.loginForm .input {
  margin: 0.5rem;
}

.submitButton {
  margin-top: 1em !important;
  background-color: #4DB5D6 !important;
  color: white !important;
  width: 50%;
  margin-left: 25% !important;
}

.loginForm button {
  justify-content: center !important;
}

.bottomLinks {
  display: flex;
  justify-content: space-around;
  grid-gap: 8em;
  gap: 8em;
  font-size: 1rem;
  margin: 1em 0 0.5em 0;
}

.bottomLinks div {
  margin: 1rem;
}

.hamburgerButton Button {
  display: none !important;
  color: white;
  background-color: transparent !important;
  margin-top: 10px !important;
  font-size: 1rem !important;
}

.hamburgerButton i {
  color: #ED3030;
  font-size: 1.9em !important;
}

.showBorder {
  border: 2px solid red;
}

/* @media screen and (max-width: 960px) {
  .navLogo img {
    transition: all 0.7s ease-out;
    transform: scale(1, 1);
  }
} */

@media screen and (max-width: 768px) {
  .loginDropDown {
    width: 260px;
  }
  .loginForm{
    width: 260px;
  }
  .bottomLinks {
    display: flex;
    justify-content: space-around;
    grid-gap: 2em;
    gap: 2em;
    font-size: 1rem;
    margin: 0.5em 0 0.2em 0;
  }
  
  .bottomLinks div {
    margin: 0.5rem;
  }
  .nav ul {
    display: none; /* Hides the navigation links */
  }

  .navbar {
    margin-bottom: 10vh;
  }

  .navLogo {
    margin-top: 5px;
  }


  .navLogo img {
    width: 3.5rem;
    height: 3rem;
  }

  .navLogoImgScroll {
    margin-top: 0 !important;
    transition: all 0.7s ease-out;
    transform: scale(0.9, 0.9);
  }

  .loginButton {
    margin-top: 1vh !important;
    max-height: 110px;
    margin-top: 0;
    font-size: 2rem !important;
    /* font-size: 1.5rem; */
  }


  .loginButtonScroll {
    margin-top: 0.5vh !important;
    font-size: 1.8rem;
  }

  .loginButton img {
    display: inline; /* Show the user icon */
    
  }

  .navLogo img {
    /* transition: all 0.7s ease-out;
    transform: scale(0.6, 0.6); */
    
  }

  .dropdown i {
    display: inline !important; /* Ensure the user icon is visible */
    margin-left: -10px;
    /* font-size: 1.3em !important; */
  }
}

body {

    background-color: #FFFDF9;
}



.splashComponent {
    width: 100%;
    padding-bottom: 15vh;
    
}

.splashTopSection {
    padding-bottom: 8vh;
    padding-top: 15vh
}

.splashTextSection {
    /* border: 2px solid #3bf50d; */
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; 
}


.splashHeading, .splashHeadingMobile {
    color: #ED3030; 
    font-weight: bold;
    font-size: 2.8rem;
}

.splashHeadingMobile {
    display: none;
}

.splashText {
    font-size: 1.4em;
    line-height: 1.1;
    color: #727070;
    text-align: justify;
}
.splashButton {
    color: #4DB5D6;
}

.imgSplash {
    /* width: 800px; */
    /* border: 2px solid #3bf50d; */
    /* justify-content: flex-end; */
}

.imgSplash img {
    object-fit: contain;
    box-shadow: -2px 2px 169px 10px rgba(0, 0, 0, 0.25);
    color: #ED3030 ;
    max-width: 100%;
    max-height: 100%; 
    transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
}

.splashBottomSection {
  padding-bottom: 5vh;
}

.splashBottomItem {


}

.splashBottomItemHeading {
    font-weight: bold;
}

.splashBottomItemText {
    color: #727070;
}

.splashButton {
    background-color: #4DB5D6 !important;
    color: white !important;

  }




@media screen and  (max-width: 800px) {

    .splashHeading, .splashText, .splashButtonContainer {
        text-align: center;
    }

    .imgSplash img {
        transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -webkit-transform: scale(0.9, 0.9);
    }    

    .splashHeading {
        /* display: none; */
    }

    .splashText {
        font-size: 1.3em;
        text-align: justify;
        margin: 0px 30px;
    }

    .noPadding {
        padding: 0px !important;
    }

    .splashHeadingMobile {
        display: none;
        /* position: absolute;
        display: flex;
        justify-content: center;
        right: 20% !important;
        top: 30vh !important;
        z-index: 1; */
       
    }
    .splashTopSection {
        padding-bottom: 8vh;
        padding-top: 1vh
    }
 
  }

  @media screen and  (max-width: 1200px) { 

    .splashHeading {
        font-size: 2rem;
    }

  }


  * {
      /* outline: 2px solid red; */
  }
.dataInsightsComponent {
    height: 100%;
    padding-bottom: 25vh;
    width: 100vw;
}

.dataInsightsContainer {
    background-image: linear-gradient(-45deg, #4DB5D6 50%, #ED3030 100%);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
    width: 100vw;
}

.dataInsight {
    display: flex;
    position: relative;
    justify-content: space-around;
}

.col1 {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    animation: moveLeftRight 9s ease-in-out infinite;
}

.col2 {
    animation: moveLeftRight 8s ease-in-out infinite;
}

.col3 {

    animation: moveLeftRight 9s ease-in-out infinite;
}

.col3row1 {
    display: flex;
    flex-direction: row;
}

.col1, .col2, .col3 {
    position: relative;
}





.dataInsightsHeading {
    z-index: 10;
    font-size: 3em;
    color: white;
    font-weight: bolder;
    line-height: 1em;
    margin-top: 30px;
    margin-bottom: 30px;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    word-spacing: 100vw;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: grey;
    
}

.widget {
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
}

.showBorder {
    border: 2px solid #3bf50d;
}


.widgets {
    display: flex;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    75% {
        background-position: 50% 75%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes moveLeftRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-200px);
    }
}

@keyframes moveLeftRightAgro {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-550px);
    }
}

@media screen and (max-width: 800px) {
    .dataInsightsComponent {
        overflow: hidden;
    }

    .dataInsight img {
        transform: scale(0.9, 0.9);
    }

    .dataInsightsHeading {
        z-index: 99999;
        font-size: 2.5em;
    }


    .moveLeftRightLevel1 {
        animation: moveLeftRightAgro 9s ease-in-out infinite;

    }
    .moveLeftRightLevel2 {
        animation: moveLeftRightAgro 10s ease-in-out infinite;

    }
    .moveLeftRightLevel3 {
        animation: moveLeftRightAgro 9s ease-in-out infinite;

    }
}
.connectivityComponent {
    height: 100%;
    padding-bottom: 25vh;
}

.connectivityContainer {
    display: flex;
    justify-content: center;
}

.connectivityHeading {
    font-size: 4.5em;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  

    
}

.connectivitysText {
    font-size: 2em;
    line-height: 1;
    color: #727070;
    width: 90%;
 
}

.conntectorIconSection {
    display: flex;
    justify-content: center;
    grid-gap: 2.5em;
    gap: 2.5em;
}

.conntectorIconSection img {
  
    width: 100px;
    height: 100px;
    /* border: 2px solid #3bf50d;; */
    grid-gap: 1em;
    gap: 1em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.office365 {
    height: 90px !important;
}

.networkFolder {
    width: 95px !important;
}


.showBorder {
    border: none;
}






@media screen and  (max-width: 800px) {

    .connectivityHeading {
        font-size: 3em;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
        
    }

    .connectivitysText{
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 3vh;
    }

    .conntectorIconSection img {
  
        max-width: 60px;
        max-height: 60px;
       
    }

    .connectivityComponent {
        height: 100%;
        padding-bottom: 10vh;
    }
 
  }
.searchComponent {
    height: 100%;
    padding-bottom: 20vh;
}

.searchGrid {
    grid-gap: 1em;
    gap: 1em;
}

.searchCardContainer {
    height: 28vh;
    width: 100% !important;
    overflow: hidden;

}

.searchCard {
    background-color: #F1F1F2;
    border-radius: 5px;
    margin: 1.4em;
    overflow: hidden;
    padding: 1em;
}


.flexColumn {
    display: flex;
    flex-direction: row !important;

}

.verticalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchCardHeading {
    font-size: 2.5em;
    font-weight: 900;
    color: #262626;
    line-height: 1;
    width: 100%;
    text-align: center;
}

.searchCardText {
    font-size: 1.2em;
    line-height: 1.5;
    color: #58595B;
    text-align: justify;
    width: 100%;
}

.fixWidth {
    max-width: 45%;
}

#ASC,
#report,
#savedSearch,
#tryingToMigrate {
    text-align: center;
}

.oneWordMe {
    word-spacing: 100vw;
    text-align: left !important;
}

.centerText {
    text-align: center;
}

.removeAll {
    padding: 0 !important;
    margin: 0 !important;
}

.setHeight {
    height: 100%;
    
}

.setWidth {
    width: 100% !important;
}

@media screen and (max-width: 800px) {
    .searchCardHeading {
        font-size: 1.5rem;
    }

    .searchCardText {
        font-size: 1rem;
    }

    .searchCard {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .searchGrid {
        grid-gap: 0;
        gap: 0;
    }


.flexColumn {
    display: flex;
    flex-direction: column !important;

}

.oneWordMe {
    word-spacing: 0;
   
}


}

@media screen and (max-width: 1200px) {
    .searchCardHeading {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
    }

    .searchCardText {
        font-size: 1.5rem;
        margin-bottom: 3vh;
    }

    #ASC {
        line-height: 1;
    }


    #tryingToMigrate {
        line-height: 1;
        overflow: hidden;
    }
}

.trasnmitContainer {


}

.transmitComponent {
    margin-bottom: 15vh;
}

.showborder {

    /* border: 2px solid #3bf50d; */

}

.transmitImg img{
    /* transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -webkit-transform: scale(0.3, 0.3); */
    height: 600px;
    width: 600px;

}

.transmitHeading {
    font-size: 5em;
    line-height: 1;
    font-weight: bold;

}

.transmitText {
    font-size: 2em;
    line-height: 1;
    color: #727070;
}



@media screen and  (max-width: 800px) {

    .transmitHeading {
        font-size: 3em;
        font-weight: bold;
        line-height: 1;
        width: auto;
        text-align: center;
        
    }

    .transmitText{
        text-align: justify;
        font-size: 1.2em;
        margin-bottom: 3vh;

    }


    #iconsSvgWrap {
        position: relative;
       left: -50px !important;
       max-width: 120vw;
    }

    
  }
.Icons_iconsSvg__3aXkD {
	opacity: 0;
	width: 100%;
	height: auto;
}

.Icons_svgWrap__5LmES {
	width: 100%;
}

.benefitsContainer {
  padding-bottom: 10vh;
  background-color: #F3F2F2;

}

.benefitsHeading {
  font-size: 4em;
  line-height: 1;
  font-weight: bold;
  margin-top: 3vh;
}


.benefitsText {
  font-size: 1.7em;
  line-height: 1;
  color: #727070;
 
}

.benefitItemsSection {
  margin-top: 7vh;
}

.benefitsItem {
  display: flex;

}

.benefitItemIcon {
  color: #ED3030;
}

.benefitsItem img {
  max-width: 25px;
  max-height: 25px;
  margin: 1px;
  font-color: #ED3030;

}

.benefitsItemText h3 {
  color: #000000;
}
.benefitsItemText {
  color: #727070;
  margin: 4px;

}







.benefitsItem h3 {
 font-size: 1.3em;
 font-weight: bold;
 line-height: 1;
}

@media screen and  (max-width: 800px) {

  .benefitsHeading {
      font-size: 3em;
      font-weight: bold;
      line-height: 1;
      width: auto;
     
  }

  .benefitsText{
      text-align: justify;
      font-size: 1.2em;

  }
}

.privacyContainer {
  padding-bottom: 30vh;
  padding-top: 10vh;
 
}

.privacyHeadingSection {
  display: flex;
  flex-direction: column;
  justify-content: center;



}

.privacyHeading {
  font-size: 4em;
  line-height: 1;
  font-weight: bold;

}

.privacyText {
  font-size: 1.8em;
  line-height: 1;
  color: #727070;
 
}



.pricingContainer {
  padding-bottom: 20vh;
 
}

.plan {
  display: flex;
  flex-direction: column;
  grid-gap: 1.9em;
  gap: 1.9em;
  margin: 10px;
  height: 62vh;
 

}
.pricingContainerGrid {
  grid-gap: 2em;
  gap: 2em;
}

/* .planCard {
  width: 30vw !important;
} */

.pricingHeading {
  font-size: 3em;
  line-height: 1;
  font-weight: bold;
  width: 100%;
  padding: 5px;
  text-align: center;
}


.price {

  color: black;
  font-size: 1.8em;
}
.pricingText {
  font-size: 1.5em;
  line-height: 1;
  color: #727070;
  height: 12vh;
 
}

.pricingSubHeading {
  font-weight: bolder;
  font-size: 1.4em;
  line-height: 1;
  color: #1d1d1d;
  margin-bottom: 1vh;;
  display: flex;
 
}

.pricingSubText {
  font-weight: normal;
  padding-left: 5px;

}

.pricingConnectorsTypeIcon {
  display: flex;
  justify-content: space-around !important;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  font-weight: normal;
  padding-left: 10px;

}


.plan1 { 
  border: 3px solid #C4C4C4;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.plan2 { 
  border: 3px solid #00AEEF;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.plan3 { 
  border: 3px solid #B94926;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}


.planButton {
  background-color: #4DB5D6 !important;
  color: white !important;
}


.footerComponent {
  height: 50vh;
  background-color: #ED3030;
  color: white;
  font-size: 2em;
}

.footerContainer {
 
  margin: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

 
}


.footerLogo {
  filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    max-width: 4em;

    margin-bottom: 4rem;
}


.footerMainLink {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footerSubLink {
  font-size: 1rem;

}
.footerSubLink a {
  text-decoration: none !important;
  color: white !important;

}

.footerItem {
  margin-bottom: 2rem;

}

@media screen and  (max-width: 800px) {
  .footerLogo {
      max-width: 3em;

  }
  .footerMainLink {
    font-size: 2rem;

  }
  
  .footerSubLink {
    font-size: 0.9rem;
  }
  
  .footerContainer {
    flex-direction: column;
    justify-content: space-between;
  }

  .footerComponent {
    height: 60vh;
   
  }

  
}
